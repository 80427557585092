import React, { useState, useContext } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import ModalContext from '../../../../context/ModalContext'

function CF7RequestQuote(props) {
	const modalContext = useContext(ModalContext)

	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
		data.append('your-lname', form.lname)
		data.append('your-phone', form.phone)
		data.append('your-email', form.email)
		data.append('your-address', form.address)
		data.append('your-city', form.city)
		data.append('page', form.page)
		data.append('your-message', form.message)
		
		const url = 'https://deepsleepstudio.com/client/SOBEINSURANCE/wp-json/contact-form-7/v1/contact-forms/133/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					// console.log('status', response.data.status)
					// console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()

						setTimeout(() => {
							modalContext.setActive(false)
						}, 2000)
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<form
			className="grid-12 gap-1"
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>

			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="First Name" name="fname" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.fname && <p className="small margin-off">your name is required.</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="Last Name" name="lname" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.lname && <p className="small margin-off">your name is required.</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="E-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">a correct email is required.</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="Phone" name="phone" ref={register({required: false, maxLength: 15, message: "error message"})} />
				{errors.phone && <p className="small margin-off">your phone is invalid.</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="Address" name="address" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.address && <p className="small margin-off">your address is required.</p>}
			</label>
			<label className="span-6 span-12-tablet">
				<input type="text" placeholder="City" name="city" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.city && <p className="small margin-off">your city is required.</p>}
			</label>
			<label className="hidden">
				<input type="text" placeholder="Page" name="page" defaultValue={modalContext.dataAttr ? modalContext.dataAttr : props.page} ref={register({required: false})} />
			</label>
			<label className="span-12">
				<textarea rows="4" placeholder="message" name="message" ref={register} />
			</label>

			<button className="text-center span-12" type="submit">Submit {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			
			<p className="span-12 text-center message small">{message}</p>

		</form>
	)
}

export default CF7RequestQuote