import React from 'react'
import './style.scss'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons'

import CF7Basic from '../Form/CF7/Basic'

function Footer(props) {
	const image = props.image
	const copy = props.copy

  return (
		<footer id='master-footer' className='c5 grid-12'>

			{ image ?
				<Img fluid={image}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					loading='eager'
					backgroundColor="#0F1D2B"
					fadeIn={true}
				/>
			: null }

			<div className="details fs-85 text-left">
				<div className="content-box-diff" dangerouslySetInnerHTML={{__html: copy}}></div>
			</div>

		</footer>
  )
}

export default Footer