import React, { useEffect } from 'react'
import Img from 'gatsby-image'
import anime from 'animejs'

//import PropTypes from 'prop-types'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Details from './Details'

function Hero(props) {
	const slides = props.slides
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		fade: true,
		speed: 800,
		pauseOnFocus: true,
		autoplay: true,
		autoplaySpeed: 8000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	}

	useEffect(() => {
		anime({
			targets: '.element',
			translateY: ['100%',0],
			duration: 400,
			delay: 300,
			easing: 'easeInOutSine'
		})
	},[])

	const SlidesPack = slides.map((slide, i) => {

		const image = slide.img.localFile.childImageSharp.fluid
		const content = slide.wysiwyg

		return (
			<div key={`slide-${i}`} className="slide x1">
				{ image ?
					<Img fluid={image}
						imgStyle={{objectFit: 'cover'}}
						objectPosition='50% 50%'
						loading='eager'
						fadeIn={true}
					/>
				: null }

				<div className='bg-overlay'>
					<div className='bg-overlay-x'></div>
				</div>
				
				<div className='hero-content'>
					<div className="hero-content-animator">
						<div className='hero-content-box splittext-lines' dangerouslySetInnerHTML={{__html: content}} />
					</div>
				</div>
			</div>
		)
	})

	return (
		<section id="master-hero" className={`hero x0 t is-inview ${props.size}`}>
			<Slider {...settings}>
				{SlidesPack}
			</Slider>
		</section>
  )
}

export default Hero