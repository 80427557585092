import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import Footer from '../components/Footer'

import Content from "../components/Content"
import ACFContentWithImage from "../components/Content/ACF/WithImage"
import Video from "../components/Video/HTML"

export default ({ data, pageContext }) => {
  
  const footerImage = data.wordpressAcfOptions.options.footer_image?.localFile.childImageSharp.fluid
  const footerCopy = data.wordpressAcfOptions.options.footer_copy

  return (
    <>
      <main className={`c0 main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        <SEO title={data.wordpressPage.title} description="Description" />

        { data.wordpressPage.acf.sections_page ?
          <ACFContentWithImage { ...data } />
        : null }

        { data.wordpressPage.content ?
          <Content { ...data } />
        : null }

        {/* <Video file="/rain.mp4" /> */}
  
      </main>

      <Footer image={footerImage} copy={footerCopy} />
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    wordpressPage( id: { eq: $id } ) {
      id
      title
      slug
      date(formatString: "MM-DD-YYYY")
      author
      content
      acf {
        sections_page {
          ... on WordPressAcf_hero {
            slides {
              img {
                localFile {
                  childImageSharp {
                    fluid (
                      maxWidth: 1920,
                      quality: 85,
                      srcSetBreakpoints: [960,1280,1920,2560]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              wysiwyg
            }
            size
          }
          ... on WordPressAcf_content {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 70,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            content
            type
            color
          }
          ... on WordPressAcf_parallax {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 70,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            content
          }
          ... on WordPressAcf_cta {
            column {
              title
              button_link
              button_text
              description
              img {
                localFile {
                  childImageSharp {
                    fluid (
                      maxWidth: 1280,
                      quality: 70,
                      srcSetBreakpoints: [960,1280,1920,2560]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_contact {
            type
            wysiwyg
            map {
              address
              lat
              lng
              zoom
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        footer_image {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1280,
                quality: 90,
                srcSetBreakpoints: [720,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        footer_copy
      }
    }
  }
`