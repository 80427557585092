import React, {useState} from 'react'
import './style.scss'

import Img from 'gatsby-image'
import Hero from "../../../Hero"
import CF7Basic from '../../../Form/CF7/Basic'
import CF7RequestQuote from '../../../Form/CF7/RequestQuote'

import GMap from '../../../Map/GMap'
import Modal from '../../../Modal'

import { Link } from 'gatsby'

function Content(props) {
	const [toggler, setToggler] = useState(false)
	
	const item = {}

	const Sections = props.wordpressPage.acf.sections_page.map((section, i) => {
		
		if(section.__typename === 'WordPressAcf_hero') {
			const image = section.slides[0].img.localFile.childImageSharp.fluid
			const content = section.slides[0].wysiwyg

			return (
				<div key="hero-main">
					<Hero size={section.size} slides={section.slides} image={image} content={content} />
					{/* Point of reference for past hero observer threashold, so we can calculate if the user is past hero or not */}
					<div id="header-fold-breakpoint"></div>
				</div>
			)
		}
		
		if(section.__typename === 'WordPressAcf_content') {
			const content = section.content
			const image = section.img?.localFile.childImageSharp.fluid

			return (
				<section key={`section-content-${i}`} className={`content ${section.color} grid-12 is-inview ${section.type}`}>

					{ image ?
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
						/>
					: null }

					<div className={`content-box ${section.color}`}>
						<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
					</div>

				</section>
			)
		}
		
		if(section.__typename === 'WordPressAcf_parallax') {
			const content = section.content
			const image = section.img?.localFile.childImageSharp.fluid

			return (
				<section key={`section-parallax-${i}`} className={`parallax c4 grid-12 is-inview ${section.type}`}>

					{ image ?
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
						/>
					: null }

					<div className='content-box c4 t x1'>
						<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
					</div>

				</section>
			)
		}

		if(section.__typename === 'WordPressAcf_cta') {
			const columnCount = section.column.length

			const thisSection = section.column.map((col, j) => {
				const title = col.title
				const image = col.img.localFile.childImageSharp.fluid
				const btn_text = col.button_text
				const btn_link = col.button_link

				return (
					<div key={`cta-col-${i}-${j}`} className={`column x1 column-${j + 1}-${columnCount} is-inview`}>
						{ image ?
							<Img fluid={image}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
							/>
						: null }
						<div className="innards">
							<h3>{title}</h3>
							<Link to={btn_link} className="btn"><span>{btn_text}</span></Link>
						</div>
					</div>
				)
			})
			
			return (
				<section key={`section-cta-${i}`} className='cta c4 grid-12'>

					{thisSection}

				</section>
			)
		}
		
		if(section.__typename === 'WordPressAcf_contact') {
			const content = section.wysiwyg

			return (
				<section key={`section-contact-${i}`} className={`content c4 grid-12 gap-2 is-inview ${section.type}`}>

					<div id="map" className="map span-5 span-12-tablet" style={{width: '100%', height: '100%'}}>
						<GMap { ...section.map } />
					</div>

					<div className='span-7 span-12-tablet padd-4 c4'>
						<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
						<CF7Basic />
					</div>

				</section>
			)
		}
	})

  return (
		<>
			{Sections}
			<Modal>
				<h3 className="text-center uppercase">You’re a click away, Contact us today</h3>
				<CF7RequestQuote page={props.wordpressPage.title} />
			</Modal>
		</>
  )
}

export default Content